import "../styles/Footer.css";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Iso from "../assets/iso.png";

const Footer = () => {
  return (
    <>
      <div className="container beforefooter">
        <div className="content1">
          <h4>
            <i className="fa-solid fa-location-dot"></i> Location
          </h4>
          <p>
            Nagpur: W -12/12 MIDC Hingna
            <br /> Road Area, Nagpur, Maharashtra -27.
          </p>
        </div>
        <div className="contenttwo">
          <h4>
            <i className="fa-solid fa-phone"></i> Phone
          </h4>
          <p className="numbernone"><a href="tel:+7620044077">+91 7620044077</a></p>
        </div>
        <div className="content3">
          <h4>
            <i className="fa-solid fa-envelope"></i> Email
          </h4>
          <p><a href="mailto:info@metalbarns.in">info@metalbarns.in</a></p>
        </div>
      </div>

      <footer>
        <div className="container-fluid allFooterContent">
          <div className="footer1">
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="Metal Barns" className="img-fluid" />
            </Link>
            <p className="text-white">
              Metal Barns India is a leading provider of advanced steel
              structures, committed to delivering high-quality, timely, and
              cost-effective building solutions.
            </p>
           
          </div>

          <div className="footer2">
            <h4>Quick Links</h4>
            <ul>
              <li>
              <Link to="/about">
                Our Story
              </Link>
              </li>
              <li>
              <Link to="/services">
                Services
              </Link>
              </li>
              <li>
              <Link to="/projects">
                Projects
              </Link>
              </li>
            </ul>
          </div>

          <div className="footer3">
            <h4>Our Services</h4>
            <ul>
              <li>
                <a href="#">EPC for Steel Structures</a>
              </li>
              <li>
                <a href="#">Industrial and Logistics Layout Design</a>
              </li>
              <li>
                <a href="#">360 degree compliances</a>
              </li>
              <li>
                <a href="#">Innovative Design and Engineering</a>
              </li>
            </ul>
          </div>

          <div className="footer4">
            <h4>Stay Connected</h4>
            <div className="social-links">
            <Link
                  to="https://www.facebook.com/srgit/"
                  className="nav-link link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i style={{color:"white"}} className="fa-brands fa-facebook"></i>
                </Link>
              <Link
                  to="https://www.instagram.com/srgit/"
                  className="nav-link link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i style={{color:"white"}} className="fa-brands fa-instagram"></i>
                </Link>
              <Link
                  to="https://www.linkedin.com/company/srgit/"
                  className="nav-link link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i style={{color:"white"}} className="fab fa-linkedin-in"></i>
                </Link>
            </div>
            <div className="footerimg">
              <img src={Iso} alt="" />
            </div>
            
          </div>

        </div>
        <hr />
        <div className="footer5 mt-3">
            <a href="https://cms.nexaitech.com" target="_blank" className="btn btn-secondary">
              Login CMS
            </a>
          </div>
      </footer>
    </>
  );
};
export default Footer;
