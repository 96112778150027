import "../styles/Caraousel.css";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import CompanyOwned from '../assets/innovation/caraousel/latestTech/companyownedfacility.png'
import CncCutting from '../assets/innovation/caraousel/latestTech/cnccutting.png'
import Submerged from '../assets/innovation/caraousel/latestTech/submergedarcwelding.png'
import Beamline from '../assets/innovation/caraousel/latestTech/beamlineautoweld.png'
import Radial from '../assets/innovation/caraousel/latestTech/radialautodrilling.png'
import MtgWelding from '../assets/innovation/caraousel/latestTech/migtigwelding.png'
import AutoCad from '../assets/innovation/caraousel/latestTech/autocad.png'
import CompanyOwnedIcon from '../assets/innovation/companyownedicon.png'
import CncCuttingIcon from '../assets/innovation/cncicon.png'
import SubmergedIcon from '../assets/innovation/submergedarcicon.png'
import BeamlineIcon from '../assets/innovation/beamlinearweldicon.png'
import RadialIcon from '../assets/innovation/radialicon.png'
import MtgWeldingIcon from '../assets/innovation/cncicon.png'
import AutocadIcon from '../assets/innovation/autocadicon.png'


const Caraousel = () => {
  const [positions, setPositions] = useState([-3, -2, -1, 0, 1, 2, 3]);

  const update = (newActiveIndex) => {
    const newPos = positions.map((pos) => {
      const diff = pos - newActiveIndex;
      return Math.abs(diff) > 2 ? -pos : diff;
    });

    setPositions(newPos);
  };

  const handleClick = (index) => {
    update(positions[index]);
  };

  const titles = [
    'Company Owned Facility',
    'CNC Cutting',
    'Submerged Arc Welding',
    'Beamline Auto Weld',
    'Radial Auto Drilling',
    'MIG TIG Welding',
    'AutoCAD'
  ];

  const images = [
    CompanyOwned,
    CncCutting,
    Submerged,
    Beamline,
    Radial,
    MtgWelding,
    AutoCad
  ];

  return (
    <>
      <div className="container">
        <div className="caraouselHeading">
          <h1>
            <span>Latest</span> Technology
          </h1>
          <Link to="/latest-technology" className="link-button">
          <button className="text-white">
            Learn More <i className="fa-solid fa-arrow-right"></i>
          </button>
          </Link>
        </div>
        <div className="carousel">
          <ul className="carousel__list">
            {positions.map((pos, index) => (
              <li
                key={index}
                className={`carousel__item ${pos === 0 ? "carousel__item_active" : ""}`}
                data-pos={pos}
                onClick={() => handleClick(index)}
                style={{ backgroundImage: `url(${images[index]})` }}
              >
                <div className="overlay">
                  <div className="overlay__content">
                    <h3>{titles[index]}</h3>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Caraousel;
