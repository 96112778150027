import React, { useEffect, useRef, useState } from "react";
import "../styles/AboutUs.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Cornerstone from "../assets/aboutUs/InnovationCornerstone.png";
import Cornerstone from "../assets/aboutUs/ourcornerstone.jpg";
import Certificate from "../assets/aboutUs/CertificateQualityService.png";
import ISO from "../assets/aboutUs/ISO.png";
import QualityServiceBlue from "../assets/aboutUs/qualityService.png";
import CertificateGrey from '../assets/aboutUs/CertificateGreyGradient.png'
import Vision from "../assets/aboutUs/OurVision.png";
import Mission from "../assets/aboutUs/OurMission.png";
import Values from "../assets/aboutUs/OurValues.png";
import Commitment from "../assets/aboutUs/OurCommitment.png";
import CommitmentTimeline from "../assets/aboutUs/Commitment.png";
import Background1 from "../assets/aboutUs/background.png";
import Qualified from "../assets/aboutUs/Qualified.png";
import Residential from "../assets/aboutUs/ResidentialBuildings.png";
import Commercial from "../assets/aboutUs/CommercialBuildings.png";
import Warehouse from "../assets/aboutUs/Warehouses.png";
import Factory from "../assets/aboutUs/FactoryBuildings.png";
import Coldhouse from "../assets/aboutUs/ColdStorages.png";
import Partner1 from "../assets/aboutUs/partner1.png";
import Partner2 from "../assets/aboutUs/partner2.png";
import Partner3 from "../assets/aboutUs/partner3.png";
import Partner4 from "../assets/aboutUs/partner4.png";
import Partner5 from "../assets/aboutUs/partner5.png";
import Partner6 from "../assets/aboutUs/partner6.png";
import Partner7 from "../assets/aboutUs/partner7.png";
import Partner8 from "../assets/aboutUs/partner8.png";
import Partner9 from "../assets/aboutUs/partner9.png";
import Partner10 from "../assets/aboutUs/partner10.png";
import Partner11 from "../assets/aboutUs/partner11.png";
import Partner12 from "../assets/aboutUs/partner12.png";
import Partner13 from "../assets/aboutUs/partner13.png";
import Partner14 from "../assets/aboutUs/partner14.png";
import Brand1 from "../assets/aboutUs/brand1.png";
import Brand2 from "../assets/aboutUs/brand2.png";
import Brand3 from "../assets/aboutUs/brand3.png";
import Brand4 from "../assets/aboutUs/brand4.png";
import Brand5 from "../assets/aboutUs/brand5.png";
import Brand6 from "../assets/aboutUs/brand6.png";
import Brand7 from "../assets/aboutUs/brand7.png";
import Brand8 from "../assets/aboutUs/brand8.png";
import Brand9 from "../assets/aboutUs/brand9.png";
import Brand10 from "../assets/aboutUs/brand10.png";
import Brand11 from "../assets/aboutUs/brand11.png";
import Brand12 from "../assets/aboutUs/brand12.png";
import Brand13 from "../assets/aboutUs/brand13.png";
import Team from "../assets/team/Team.png";
import founder from "../assets/team/founder/CofounderBlue.png";
import hod from "../assets/aboutUs/caraouselLogos/HeadsofDepartments.png";
import senior from "../assets/aboutUs/caraouselLogos/SeniorEngineers.png";
import lead from "../assets/aboutUs/caraouselLogos/LeadSite.png";
import support from "../assets/aboutUs/caraouselLogos/SupportStaff.png";
import advisor from "../assets/aboutUs/caraouselLogos/advisors.png";
import Tabs from "./Tabs";
import BgTest from "../assets/aboutUs/aboutusb1Test.png";
import mapImage from "../assets/aboutUs/mapwithpins.png";
import Cofounder from '../assets/team/founder/Founder.png'
import HOD from '../assets/team/founder/Heads of Departments.png'
import SeniorEngi from '../assets/team/founder/Senior Engineers.png'
import Lead from '../assets/team/founder/Lead Site Engineers and Supervisors.png'
import SupportStaff from '../assets/team/founder/Support Staff.png'
import Advisors from '../assets/team/founder/Advisors.png'



const cardsData1 = [
  {
    role: "Co-Founder",
    members: [
      {
        title: "Avijit Guha",
        title1: "Co-Founder",
        description:
          "Avijit has over 20 years of experience in the field of structural engineering and project management. He has led numerous large-scale projects across India and the US, ensuring timely and cost-effective delivery of high-quality steel structures.",
        // iconSrc: '/images/customerfirst.png',
        cardIcon: Team,
      },
      {
        title: "Jaya Guha",
        title1: "Co-Founder",
        description:
          "Jaya has a wealth of experience in operations management and business development. She has been instrumental in driving the companys growth and maintaining its high standards of quality and customer satisfaction.",
        cardIcon: Team,
      },
    ],
    iconSrc: founder,
    selectedCard: Cofounder
  },
  {
    role: "Heads of Department",
    members: [
      {
        title: "Aman Singh",
        title1: "Head of Project Management",
        description:
          "Our commitment to quality is unwavering. We adhere to the highest standards in every aspect of our work, from materials and design to construction and customer service. Our focus is on delivering projects that not only meet but exceed client expectations.",
        cardIcon: Team,
      },
      {
        title: "Raj Ingle",
        title1: "Head of Operations Management",
        description:
          "With a background in operations management, Raj ensures that all processes run smoothly and efficiently, optimizing productivity and quality.",
        cardIcon: Team,
      },
      {
        title: "Sandeep Roy",
        title1: "Head of Designs",
        description:
          " Sandeep is a visionary designer, leading the team in creating innovative and efficient structural designs that meet clients needs.",
        cardIcon: Team,
      },
    ],
    iconSrc: hod,
    selectedCard: HOD
  },
  {
    role: "Senior Engineers",
    members: [
      {
        title: "Kailash Nagpure",
        title1: "Sr Engineer - Planning and Productions",
        description:
          "Kailash is responsible for planning and production, ensuring that all projects are executed flawlessly and on schedule.",
        cardIcon: Team,
      },
      {
        title: "Mickey Ramteke",
        title1: "Sr Engineer - Site Co-ordination",
        description:
          "Mickey coordinates site activities, ensuring seamless communication and execution between the office and the field.",
        cardIcon: Team,
      },
    ],
    iconSrc: senior,
    selectedCard: SeniorEngi
  },
  {
    role: "Lead Site Engineers and Supervisors",
    members: [
      {
        title: "Rajat Gond",
        title1: "Lead Site Engineer",
        description:
          "Rajat oversees site engineering tasks, ensuring the structural integrity and quality of all projects.",
        cardIcon: Team,
      },
      {
        title: "Lalu Prasad",
        title1: "Lead Site Supervisor",
        description:
          " Lalu supervises site activities, ensuring adherence to safety standards and efficient project execution.",
        cardIcon: Team,
      },
    ],
    iconSrc: lead,
    selectedCard: Lead
  },
  {
    role: "Support Staff",
    members: [
      {
        title: "Pankaj Khokhare",
        title1: "Sr Executive Accounts",
        description:
          "Pankaj manages the financial aspects of the company, ensuring accurate accounting and financial planning.",
        cardIcon: Team,
      },
      {
        title: "Roshan Pawar",
        title1: "Lead GST and Taxation",
        description:
          "Roshan handles GST and taxation matters, ensuring compliance with all relevant regulations.",
        cardIcon: Team,
      },
      {
        title: "Mangesh Joshi",
        title1: "Lead Procurement and Administration",
        description:
          "Mangesh oversees procurement and administrative functions, ensuring smooth operations and resource management.",
        cardIcon: Team,
      },
    ],
    iconSrc: support,
    selectedCard: SupportStaff
  },
  {
    role: "Advisors",
    members: [
      {
        title: "Rameet Trehan",
        description:
          "Rameet is an experienced entrepreneur with over 30 years in Business & Organizational Development. He has led major real estate projects worth over 3000 Crores in Delhi and driven investments in renewable energy, real estate, infrastructure, and hospitality. He holds a Gold Medal MBA in Economics and International Trade from the University of Delhi.",
        cardIcon: Team,
      },
      {
        title: "Vivek B Singh",
        description:
          "A serial entrepreneur and investor, Vivek B Singh is the Director of Swasara Resorts and SunCity Group. With extensive experience in various industries, Vivek brings a wealth of knowledge and strategic insight to our team.",
        cardIcon: Team,
      },
    ],
    iconSrc: advisor,
    selectedCard: Advisors
  },
];

// const cardsData1 = [
//   {
//     title: 'Co-Founder',
//     description: 'At MBI, every member of our team is trained with a customer-first approach. Our professionals, drawing from experience with top companies in India, ensure that quality service and customer satisfaction are at the core of our operations. We build solutions tailored to our customers needs and expectations.',
//     iconSrc: '/images/customerfirst.png',
//     cardIcon: {founder},
//   },
//   {
//     title: 'Heads of Department',
//     description: 'Our commitment to quality is unwavering. We adhere to the highest standards in every aspect of our work, from materials and design to construction and customer service. Our focus is on delivering projects that not only meet but exceed client expectations.',
//     iconSrc: '/images/qualitycommitment.png',
//     cardIcon: {hod},
//   },
//   {
//     title: 'Senior Engineers',
//     description: 'Innovation is at the heart of everything we do. We continuously strive to improve our processes, incorporate new technologies, and find creative solutions to meet the evolving needs of our clients. Our goal is to stay ahead of the curve and set new industry standards.',
//     iconSrc: '/images/driveninnovation.png',
//     cardIcon: {senior},
//   },
//   {
//     title: 'Lead Site Engineers and Supervisors',
//     description: 'Innovation is at the heart of everything we do. We continuously strive to improve our processes, incorporate new technologies, and find creative solutions to meet the evolving needs of our clients. Our goal is to stay ahead of the curve and set new industry standards.',
//     iconSrc: '/images/timemanagedprojects.png',
//     cardIcon: {lead},
//   },
//   {
//     title: 'Support Staff',
//     description: 'Innovation is at the heart of everything we do. We continuously strive to improve our processes, incorporate new technologies, and find creative solutions to meet the evolving needs of our clients. Our goal is to stay ahead of the curve and set new industry standards.',
//     iconSrc: '/images/timemanagedprojects.png',
//     cardIcon: {support},
//   },
//   {
//     title: 'Advisors',
//     description: 'Innovation is at the heart of everything we do. We continuously strive to improve our processes, incorporate new technologies, and find creative solutions to meet the evolving needs of our clients. Our goal is to stay ahead of the curve and set new industry standards.',
//     iconSrc: '/images/timemanagedprojects.png',
//     cardIcon: {advisor},
//   },
// ];

const DataBar = ({ icon, label, value, percentage }) => (
  <div className="row align-items-center mb-3 panprogress">
    <div className="col-2 text-center">
      <img src={icon} alt={label} className="img-fluid" />
    </div>
    <div className="col-6">
      <strong>{label}</strong>
    </div>
    <div className="col-2 text-end">{value}</div>
    <div className="col-12">
      <div className="progress">
        <div className="progress-bar" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  </div>
);

const AboutUs = () => {
  // for desired sections scrolling
  const location = useLocation();
  const targetSectionRef = useRef(null);
  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1); // Remove the '#' character
      const section = document.getElementById(sectionId);
      console.log("Section:", section); // Debugging line
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const [selectedCard, setSelectedCard] = useState(0);

  const data = [
    {
      icon: Coldhouse,
      label: "Cold Storages:",
      value: "9.25 Lac sqft",
      percentage: 100,
    },
    {
      icon: Factory,
      label: "Factory Buildings With Cranes:",
      value: "0.98 Lac sqft",
      percentage: 10.6,
    },
    {
      icon: Warehouse,
      label: "Warehouses:",
      value: "3.5 Lac sqft",
      percentage: 37.8,
    },
    {
      icon: Commercial,
      label: "Commercial Buildings:",
      value: "0.2 Lac sqft",
      percentage: 2.2,
    },
    {
      icon: Residential,
      label: "Residential Buildings:",
      value: "0.07 Lac sqft",
      percentage: 0.76,
    },
  ];

  return (
    <>
      <section className="aboutbgtest">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={BgTest} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentabout">
            <h1>
              <span>Our</span> Story
            </h1>
            <p>
              A Decade of Shaping India’s Infrastructure <br />
              with Steel and Innovation{" "}
            </p>
          </div>
        </div>
      </section>

      {/* Innovation Cornerstone */}
      <section className="cornerstone">
        <div className="container">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 col-md-6">
                <h2>
                  <span>Innovation</span> as Our Cornerstone
                </h2>
                <h5>Engineering Today for a Sustainable Tomorrow</h5>
                <p>
                  At Metal Barns India, we revolutionize the establishment of
                  industrial facilities by minimizing the entrepreneur's
                  involvement in non-productive tasks. Founded with a vision of
                  delivering high-quality building solutions, we leverage
                  advanced technology to enhance decision-making and ensure
                  projects meet precise design specifications. Our commitment to
                  timely and cost-effective execution empowers stakeholders and
                  fosters growth, building a legacy of trust and reliability in
                  the industry.
                </p>
                <Link to="/founders" className="link-button">
                  <button>
                    Our Founders <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </Link>
              </div>
              <div class="col-12 col-md-6">
                <img
                  src={Cornerstone}
                  alt="Innovation Cornerstone"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Black four cards */}
      <section className="blackCard">
        <div className="container">
          <div className="card">
            <img src={Vision} alt="Our Vision" className="img-fluid" />
            <h3 className="title1">Our Vision</h3>
            <div className="bar">
              <div className="emptybar"></div>
              <div className="filledbar"></div>
            </div>
            <p className="text-white">
              MBI aims to become the premier provider of innovative structural
              solutions for SMEs across the country.
            </p>
          </div>
          <div className="card">
            <img src={Mission} alt="Our Mission" className="img-fluid" />
            <h3 className="title1">Our Mission</h3>
            <div className="bar">
              <div className="emptybar"></div>
              <div className="filledbar"></div>
            </div>
            <p className="text-white">
              MBI is committed to delivering project-managed, cost-effective,
              and ROI-capable steel buildings to its customers.
            </p>
          </div>
          <div className="card">
            <img src={Values} alt="Our Values" className="img-fluid" />
            <h3 className="title1">Our Values</h3>
            <div className="bar">
              <div className="emptybar"></div>
              <div className="filledbar"></div>
            </div>
            <p className="text-white">
              MBI believes in producing time-managed, well-engineered, and
              honest steel structures. Innovation is at the core of our
              existence.
            </p>
          </div>
          <div className="card">
            <img
              src={Commitment}
              alt="Our Commitment"
              className="card-img-top img-fluid"
            />
            <h3 className="title1">Our Commitment</h3>
            <div className="bar">
              <div className="emptybar"></div>
              <div className="filledbar"></div>
            </div>
            <p className="text-white">
              We are committed to providing the best in design, product,
              material, and service today and tomorrow.
            </p>
          </div>
        </div>
      </section>

      {/* Quality Service */}
      <section className="qualityService">
        <div className="container">
          <h2>
            {" "}
            <span> Quality </span> Service Always{" "}
          </h2>
          <div className="row">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center ISOLeft">
              <img src={ISO} alt="ISO" className="img-fluid iso" />
              <p>
                At MBI, our design approach blends traditional engineering
                excellence with cutting-edge technology. Our in-house team of
                engineers, supported by our Gurugram-based structural design
                associates, ensures meticulous planning and execution.
                Highlights include prestigious projects like the Aeroplane
                Hangar for the Prime Minister and numerous large PEB projects,
                metro projects, and defense infrastructure. Our commitment to
                quality and innovation drives every project from initial CAD
                drawings to final completion.
              </p>
              {/* <img src={CertificateGrey} alt="Certificate" className="bottom-left-image"/> */}
            </div>
            <div className="col-12 col-md-6 position-relative">
              <img
                src={Certificate}
                alt="Quality Service"
                className="img-fluid"
              />
              <img
                src={QualityServiceBlue}
                alt="Responsive Bottom Right"
                className="bottom-right-image"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Meet our Pioneers */}
      <section className="pioneers">
        <div className="container">
          <div className="pioneerHeading">
            <h1 className="text-left">
              Meet Our<span> Pioneers </span>
            </h1>
          </div>
          <h6 className="text-left">The Minds Behind the Steel</h6>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="w-100 p-4 bg-transparent position-relative">
                {cardsData1.map((card, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-center p-4 cursor-pointer transition-all duration-300 ${
                      selectedCard === index
                        ? "bg-custom selected-card"
                        : "bg-white text-dark"
                    }`}
                    onClick={() => setSelectedCard(index)}
                  >
                    <img
                      src={
                        selectedCard === index
                          ? card.selectedCard
                          : card.iconSrc
                      }
                      alt={card.role}
                      className="me-4"
                      style={{ width: "32px", height: "32px" }}
                    />
                    <span>{card.role}</span>
                    {selectedCard === index && <div className="arrow-right" />}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-8">
              {selectedCard !== null && (
                <div className="container">
                  <div className="row">
                    {cardsData1[selectedCard].members.map((member, idx) => (
                      <div key={idx} className="card col-md-12 p-3 mb-4">
                        <div className="row">
                          <div className="col-md-4">
                            <img
                              src={member.cardIcon}
                              alt={member.title}
                              className="w-100"
                            />
                          </div>
                          <div className="col-md-8">
                            <div className="card-block">
                              <h5 className="card-title">{member.title}</h5>
                              <h6 className="card-title1">{member.title1}</h6>
                              <p className="card-text text-justify">
                                {member.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Company Timeline */}
      <section className="companyTimeline">
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-4">
              <img
                src={CommitmentTimeline}
                alt="Commitment Timeline"
                className="img-fluid"
              />
              <h3 className="text-white">Professionally Qualified</h3>
              <p className="text-white">
                All members of the key team are professionally qualified min. BE
                & MBA from prestigious institutions in the country.
              </p>
            </div>
            <div className="col-md-4">
              <img
                src={Background1}
                alt="Background Timeline"
                className="img-fluid"
              />
              <h3 className="text-white">Professional Background</h3>
              <p className="text-white">
                Members of the key team have established track records in Apex
                Large Corporate.
              </p>
            </div>
            <div className="col-md-4 mb-5">
              <img
                src={Qualified}
                alt="Qualified Timeline"
                className="img-fluid"
              />
              <h3 className="text-white">Professional Commitment</h3>
              <p className="text-white">
                Each member of the MBI Team is trained to conduct business and
                interactions in professional manner.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Horizontal Timeline 2014 - 2023 */}
      <Tabs />

      {/* PAN India Installation */}
      <section className="panIndia">
        <div className="container">
          <div className="panIndiaHeading">
            <h1>
              <span> PAN India </span>Installations
            </h1>
            <Link to="/projects" className="link-button">
              <button>
                View Projects <i className="fa-solid fa-arrow-right"></i>
              </button>
            </Link>
          </div>
          <div className="row">
            <h5>Nationwide Reach and Expertise</h5>
            <div className="col-12 col-md-4 d-flex flex-column leftsideCol">
              <p>
                MBI buildings are competitive and viable in multiple states. Our
                installations span major industrial cities across Near East,
                West, North, and South India.
              </p>
              <div className="mt-5">
                {data.map((item, index) => (
                  <DataBar key={index} {...item} />
                ))}
              </div>
            </div>
            <div className="col-12 col-md-8 position-relative">
              <div className="map-container">
                <img src={mapImage} alt="Map of India" className="map-image" />
                <div className="pin-card pin-1">
                  <div className="card-content">
                    <h6>Jaipur, Rajasthan</h6>
                  </div>
                </div>
                <div className="pin-card pin-2">
                  <div className="card-content">
                    <h6>Bangalore, Karnataka</h6>
                  </div>
                </div>
                <div className="pin-card pin-3">
                  <div className="card-content">
                    <h6>Jamnagar, Gujarat</h6>
                  </div>
                </div>
                <div className="pin-card pin-4">
                  <div className="card-content">
                    <h6>Purnea, Bihar</h6>
                  </div>
                </div>
                <div className="pin-card pin-5 pinPara">
                  <div className="card-content">
                    <p>
                      Apart from numerous other prestige installations in{" "}
                      <span>Nagpur, Raipur, Hyderabad and Rourkela</span>.
                    </p>
                  </div>
                </div>
                <div className="pin-card pin-6 pinPara">
                  <div className="card-content">
                    <p>
                      MBI has delivered buildings in stringent{" "}
                      <span>safety conditions incl OHSAS</span> , third party
                      inspection in Fabrication and Erection from BVQI{" "}
                      <span>(Chennai, Pune, Kolkata)</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* Accordion for pin cards */}
              <div className="accordion mt-4" id="pinAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="pin1Heading">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pin1Collapse"
                      aria-expanded="false"
                      aria-controls="pin1Collapse"
                    >
                      Jaipur, Rajasthan
                    </button>
                  </h2>
                  <div
                    id="pin1Collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="pin1Heading"
                    data-bs-parent="#pinAccordion"
                  >
                    <div className="accordion-body">
                      50,000 Sft - Iron Mountain , EH 10 Mtr, RCC Hybrid, Data &
                      Fire Safe Critical Structure.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="pin2Heading">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pin2Collapse"
                      aria-expanded="false"
                      aria-controls="pin2Collapse"
                    >
                      Bangalore, Karnataka
                    </button>
                  </h2>
                  <div
                    id="pin2Collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="pin2Heading"
                    data-bs-parent="#pinAccordion"
                  >
                    <div className="accordion-body">
                      Centre for Civil Aviation – DRDO – HAL Campus. 40,000 Sft
                      Aircraft Hangar 60 Mtr x 15 Mtr wide Automated PEB doors.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="pin3Heading">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pin3Collapse"
                      aria-expanded="false"
                      aria-controls="pin3Collapse"
                    >
                      Jamnagar, Gujarat
                    </button>
                  </h2>
                  <div
                    id="pin3Collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="pin3Heading"
                    data-bs-parent="#pinAccordion"
                  >
                    <div className="accordion-body">
                      10000 Sft - Calderys India Ltd , warehouse for Reliance
                      Jamnagar. EH 6 Mtr, Non Crane.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="pin4Heading">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pin4Collapse"
                      aria-expanded="false"
                      aria-controls="pin4Collapse"
                    >
                      Purnea, Bihar
                    </button>
                  </h2>
                  <div
                    id="pin4Collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="pin4Heading"
                    data-bs-parent="#pinAccordion"
                  >
                    <div className="accordion-body">
                      25,000 sqft - Himalaya Agro Industries, Solar Roof.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="pin5Heading">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pin5Collapse"
                      aria-expanded="false"
                      aria-controls="pin5Collapse"
                    >
                      Others
                    </button>
                  </h2>
                  <div
                    id="pin5Collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="pin5Heading"
                    data-bs-parent="#pinAccordion"
                  >
                    <div className="accordion-body">
                      <ol>
                        <li>
                          {" "}
                          MBI has delivered buildings in stringent
                          <span>safety conditions incl OHSAS</span> , third
                          party inspection in Fabrication and Erection from BVQI
                          <span>(Chennai, Pune, Kolkata)</span>
                        </li>
                        <li>
                          Apart from numerous other prestige installations in
                          <span>Nagpur, Raipur, Hyderabad and Rourkela</span>.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Clients */}
      <section className="container clientss">
        <div
          className="clientssHeading"
          id="our-clients"
          ref={targetSectionRef}
        >
          <h1 className="text-center">
            <span>Our</span> Clients
          </h1>
        </div>
        <div className="row">
          {[
            Partner1,
            Partner2,
            Partner3,
            Partner4,
            Partner5,
            Partner6,
            Partner7,
            Partner8,
            Partner9,
            Partner10,
            Partner11,
            Partner12,
            Partner13,
            Partner14,
          ].map((partner, index) => (
            <div className="col-6 col-md-3 mb-4" key={index}>
              <div className="card">
                <img
                  className="img-fluid"
                  src={partner}
                  alt={`Partner ${index + 1}`}
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Brands we use */}
      <section className="container brandss">
        <div className="brandsHeading" id="our-brands" ref={targetSectionRef}>
          <h1 className="text-center">
            <span>Brands</span> We Use
          </h1>
        </div>
        <div className="row">
          {[
            Brand1,
            Brand2,
            Brand3,
            Brand4,
            Brand5,
            Brand6,
            Brand7,
            Brand8,
            Brand9,
            Brand10,
            Brand11,
            Brand12,
            Brand13,
          ].map((partner, index) => (
            <div className="col-6 col-md-3 mb-4 mt-5" key={index}>
              <div className="card">
                <img
                  className="img-fluid"
                  src={partner}
                  alt={`Partner ${index + 1}`}
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
export default AboutUs;
