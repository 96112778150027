import { useEffect, useRef } from "react";
import React from "react";
import "../styles/Home.css";
import { Link } from "react-router-dom";
// import Aboutimg from "../assets/aboutusHomepage.png";
import Aboutimg from '../assets/aboutUsHome111.jpg'
import Partner1 from "../assets/clients/partner1.png";
import Partner2 from "../assets/clients/partner7.png";
import Partner3 from "../assets/clients/partner9.png";
import Partner4 from "../assets/clients/partner13.png";
import Partner5 from "../assets/clients/partner3.png";
import Client1 from "../assets/clients/client1.png";
import Client2 from "../assets/clients/client2.png";
import Client3 from "../assets/clients/client5.png";
import Client4 from "../assets/clients/client8.png";
import Member1 from "../assets/team/Team.png";
import Trikota from "../assets/team/Trikona.png";
import Avp from "../assets/team/AVP.png";
import Industrial from "../assets/Industrialand.png";
import Epc from "../assets/EPCfor.png";
import Degree from "../assets/360degree.png";
import Innovation from "../assets/InnovativeDesignandEngineering.png";
import logo2014 from '../assets/logo2014.png'
import logo2015 from '../assets/logo2015.png'
import logo2016 from '../assets/logo2016.png'
import logo2017 from '../assets/logo2017.png'
import logo2018 from '../assets/logo2018.png'
import logo2020 from '../assets/logo2020.png'
import logo2021 from '../assets/logo2021.png'
import logo2022 from '../assets/logo2022.png'
import logo2023 from '../assets/logo2023.png'
import Over10 from '../assets/overtenLogo.png'
import ServicesBg from '../assets/homeServiceSecBg.png'
import Form from '../components/Form'
import CountUp from 'react-countup'
import Profi1 from '../assets/ProFea1.png'
import Profi2 from '../assets/ProFea2.png'
import { useState } from "react";

const Home = () => {
  const [isMuted, setIsMuted] = useState(true); 
  const audioRef = useRef(null);

  const toggleAudio = () => {
    if (isMuted) {
      audioRef.current.play(); 
    } else {
      audioRef.current.pause(); 
    }
    setIsMuted(!isMuted); 
  };

  // for desired sections routing
  const handleButtonClick = () => {
    console.log('Navigating to /about with hash #our-clients');
    window.location.href = '/about#our-clients';
  };

  const handleButtonClick2 = () => {
    window.location.href = '/about#our-brands';
  };

  // counting effect
  const [startCounting, setStartCounting] = useState(false);
  const experienceRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCounting(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );
    if (experienceRef.current) {
      observer.observe(experienceRef.current);
    }

    return () => {
      if (observer && observer.disconnect) {
        observer.disconnect();
      }
    };
  }, []);


  
  // home page's form logic
  const [isFormVisible, setFormVisible] = useState(false);

  const showForm = () => {
    setFormVisible(true);
  };

  const closeForm = () => {
    setFormVisible(false);
  };

  // timeline components logic
  const timelineItems = useRef([]);
  useEffect(() => {
    setEqualHeights(timelineItems.current);
  }, []);

  const setEqualHeights = (elements) => {
    let maxHeight = 0;
    elements.forEach((el) => {
      if (el) {
        const height = el.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      }
    });
    elements.forEach((el) => {
      if (el) {
        el.style.height = `${maxHeight}px`;
      }
    });
  };


  return (
    <>
      <section className="hero">
        <div className="hero-img">
          <video
            className=""
            src="/videos/metalbarnshome.mp4"
            autoPlay
            loop
            muted
            playsInline
          ></video>
          <div className="hero-text">
            <h3 className="heading text-white">Building The Future With</h3>
            <h1 className="heading text-white">
              Advanced <span>Steel Structures</span>
            </h1>
            <p className="text-white">
              Innovate, Design, and Build with India’s Leading Steel Solutions
              Provider
            </p>
            <div className="buttons">
              <button
                className="btn .btn-primary getFreeQuote"
                onClick={showForm}
              >
                Get a Free Quote
              </button>
              <Link to="/services">
                <button className="btn btn-secondary">
                  Explore our Solutions
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {isFormVisible && <Form closeForm={closeForm} />}

      {/* aboutUs */}
      <section className="aboutusHome">
        <div className="container">
          <img src={Aboutimg} className="img-fluid" />
          <div className="card custom-border-card">
            <div className="card-body">
              <h5 className="card-title">About Us</h5>
              <h1>Service For Sure</h1>
              <p className="card-text">
                From inception in 2014 to a powerhouse in the steel industry,
                discover our journey of innovation and excellence.
              </p>
              {/* <a href="#" className="btn btn-primary">
                Learn More
              </a> */}
            </div>
          </div>
        </div>
      </section>

      {/* Our Service */}
      <section className="servicesService position-relative">
        <div className="container-fluid">
          <div className="row justify-content-center position-relative">
            <div className="col-md-3 mb-4 d-flex justify-content-center">
              <div className="card overlay-card">
                <div className="card-body">
                  <h5 className="card-title">15 Lakh Sq. Ft</h5>
                  <p className="card-text">Area Built</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4 d-flex justify-content-center">
              <div className="card overlay-card overlay-card2">
                <div className="card-body">
                  <h5 className="card-title">104 Acres</h5>
                  <p className="card-text">
                    360 Degree PEB Structures Constructed
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                src={ServicesBg}
                className="img-fluid w-100"
                alt="Services Background"
              />
            </div>
          </div>
          <div className="container-fluid row">
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="box">
                <img
                  src={Industrial}
                  className="img-fluid mx-auto d-block"
                  alt="Industrial and Logistics"
                />
                <h4>Industrial and Logistics Layout Design</h4>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="box">
                <img src={Epc} className="img-fluid mx-auto d-block" alt="EPC For Steel" />
                <h4>EPC for Steel Structures</h4>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="box">
                <img
                  src={Innovation}
                  className="img-fluid mx-auto d-block"
                  alt="Innovative designs"
                />

                <h4>Innovative Design and Engineering</h4>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="box">
                <img
                  src={Degree}
                  className="img-fluid mx-auto d-block"
                  alt="Degree Compliances"
                />
                <h4>360 degree compliances</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Journey timeline */}
      <section className="timeline">
        <div className="info">
          <h2 className="text-white">
            Over <span>10</span> Years
          </h2>
          <img src={Over10} className="img-fluid" alt="overtenyears" />
          <h4 className="text-white">
            Journey of Excellence From 2014 to 2023
          </h4>
          <p className="text-white">
            Discover the remarkable journey of Metal Barns India over the past
            decade. Our commitment to innovation, quality, and customer
            satisfaction has driven our growth from a small fabricator to a
            leading provider of turnkey solutions in the steel building
            industry.
          </p>
          {/* <p>
            <a href="">Learn more &gt;</a>
          </p> */}
        </div>

        <ol>
          {[
            {
              year: "Inception Oct 2014",
              text: "Fabricator of PEB Structure.",
              text1: "Team Size: 19 people",
              iconSrc: logo2014,
            },
            {
              year: "Oct 2015",
              text: "Expanded fabrication capabilities",
              text1: "Team Size: 25 people.",
              iconSrc: logo2015,
            },
            {
              year: "Oct 2016",
              text: "Steel Building provider with own erection team",
              text1: "Team Size: 30 people",
              iconSrc: logo2016,
            },
            {
              year: "Oct 2017",
              text: "Steel Building & Services Provider",
              text1: "Team Size: 59 people",
              iconSrc: logo2017,
            },
            {
              year: "Oct 2018",
              text: "Solutions Provider Steel Buildings",
              text1: "Team Size: 70 people",
              iconSrc: logo2018,
            },
            {
              year: "Oct 2020",
              text: "Critical Building Design & Optimization",
              text1: "Team Size: 83 people",
              iconSrc: logo2020,
            },
            {
              year: "Oct 2021",
              text: "Continued growth in projects and team",
              text1: "Team Size: 100+ people",
              iconSrc: logo2021,
            },
            {
              year: "Oct 2022",
              text: "Turnkey solutions with comprehensive services",
              text1: "Team Size: 100+ people",
              iconSrc: logo2022,
            },
            {
              year: "Oct 2023",
              text: "Turnkey Solution - Both Steel and Civil",
              text1: "Team Size: 276+ people",
              iconSrc: logo2023,
            },
          ].map((item, index) => (
            <li key={index}>
              <div ref={(el) => (timelineItems.current[index] = el)}>
                <span className="logoYear">
                  <img src={item.iconSrc} />
                </span>
                <time className="timelineYear">{item.year}</time>{" "}
                <span className="timelineText">{item.text}</span> <br />
                <span className="text1">{item.text1}</span>
              </div>
            </li>
          ))}
        </ol>
      </section>

      {/* Project completed */}
      <section className="experience" ref={experienceRef}>
        <div className="container-fluid">
          <div className="items">
            <div className="item1">
              <div className="content1">
                {startCounting && (
                  <h1>
                    <CountUp end={10} duration={2} />+
                  </h1>
                )}
                <p>Years of Industrial Experience</p>
              </div>
            </div>
            <div className="item2">
              <div className="content2">
                <div className="fcol">
                  <h4 className="text-white">
                    Large Corporate
                    <br /> Clients
                  </h4>
                  {startCounting && (
                    <h2 className="text-white">
                      <CountUp end={25} duration={2} />+
                    </h2>
                  )}
                </div>
                <div className="scol">
                  <h4 className="text-white">
                    Our Team
                    <br /> Members
                  </h4>
                  {startCounting && (
                    <h2 className="text-white">
                      <CountUp end={280} duration={2} />+
                    </h2>
                  )}
                </div>
              </div>
              <div className="btn">
                <button className="btnExperience btn text-white">
                  Our Experience
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Projects */}
      <section className="container-fluid featuredProjects">
        <div className="container featuredHeading">
          {/* <h4>Our Projects</h4> */}
          <h1>
            <h5 style={{color:"#018DC8"}}>Our Projects</h5>
            Featured <span>Projects</span>
          </h1>
          <Link to="/projects">
            <button>
              Discover More <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>
        </div>
        <div className="card-containerFeatured">
          <div className="card card1featured">
            <div className="allfeaturedcontent">
            <img src={Profi1} className="card-img-top" alt="Featured projects" />
            <div class="card-body">
              <h2>20 Acres Trinity Fructa Unit III, Assam </h2>
              <p className="card-text text-white">
              MBI delivered a high-quality, 80,000 sqft steel building for
              Trinity Fructa Unit III, on time and within budget.
              </p>
              </div>
            </div>
          </div>

          <div className="card card2featured">
          <div className="allfeaturedcontent">
            <img src={Profi2} className="card-img-top" alt="Featured projects" />
            <div className="card-body">
              <h2>11 Acre Turnkey<br /> Project</h2>
              <p className="card-text text-white">
              MBI completed a comprehensive 20-acre project,integrating advanced
              design,manufacturing,& installation services to deliver robust
              steel structures on schedule & within budget.
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Meet Founders */}
      <section className="container-fluid team">
        <div className="container founderHeading">
          <h1>
            Our <span>Founders</span>
          </h1>
          <Link to="/founders">
            <button>
              Our Team <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>
        </div>
        <div className="card-container">
          <div className="card card1">
            <img src={Member1} className="card-img-top" alt="meet team" />
            <div class="card-body">
              <h2>Avjit Guha</h2>
              <h3>Co-Founder</h3>
              <p className="card-text">
                Driving innovation and excellence in steel structures.
              </p>
              <div className="logo">
                <img src={Trikota} className="imgincard" alt="" />
                <Link to="/founders">
                  <button className="foundersArrow">
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="card card2">
            <img src={Member1} className="card-img-top" alt="meet team" />
            <div className="card-body">
              <h2>Jaya Guha</h2>
              <h3>Co-Founder</h3>
              <p className="card-text">
                Driving innovation and excellence in steel structures.
              </p>
              <div className="logo">
                <img src={Avp} className="imgincard" alt="" />
                <Link to="/founders">
                  <button>
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Clients */}
      <section className="container clients">
        <div className="clientsHeading">
          <h1>
            Our <span>Clients</span>
          </h1>
          <Link to="/about">
            <button onClick={handleButtonClick}>
              View All <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>
        </div>
        <div className="images">
          <div className="card">
            <img className="img-fluid" src={Partner3} alt="Partner 3" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Partner4} alt="partner 4" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Partner2} alt="partner" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Partner5} alt="partner" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Partner1} alt="partner" />
          </div>
        </div>
      </section>

      {/* Brands we use */}
      <section className="container partners">
        <div className="partnerHeading">
          <h1>
            <span>Brands</span> We Use
          </h1>
          <button onClick={handleButtonClick2}>
            View All <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
        <div className="images1">
          <div className="card">
            <img className="img-fluid" src={Client1} alt="client" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Client2} alt="client" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Client3} alt="client" />
          </div>
          <div className="card">
            <img className="img-fluid" src={Client4} alt="client" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
