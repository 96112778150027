import React, { useState, useEffect } from "react";
import "../styles/Innovation.css";
import { Link } from 'react-router-dom';
import Background from "../assets/aboutUs/aboutmask.png";
import Background1 from '../assets/innovation/innovationBg.png'
import Collage from "../assets/innovation/collageInnovation.jpg";
import Brand1 from '../assets/aboutUs/brand1.png'
import Brand2 from '../assets/aboutUs/brand2.png'
import Brand3 from '../assets/aboutUs/brand3.png'
import Brand4 from '../assets/aboutUs/brand4.png'
import Brand5 from '../assets/aboutUs/brand5.png'
import Brand6 from '../assets/aboutUs/brand6.png'
import Brand7 from '../assets/aboutUs/brand7.png'
import Brand8 from '../assets/aboutUs/brand8.png'
import Brand9 from '../assets/aboutUs/brand9.png'
import Brand10 from '../assets/aboutUs/brand10.png'
import Brand11 from '../assets/aboutUs/brand11.png'
import Brand12 from '../assets/aboutUs/brand12.png'
import Brand13 from '../assets/aboutUs/brand13.png'
import CustomerFirst from '../assets/innovation/customerFirst.png'
import CommitmentQuality from '../assets/innovation/Qualitycommitment.png'
import DrivenInnovation from '../assets/innovation/DrivenbyInnovations.png'
import TimeManagement from '../assets/innovation/TimemanagedProjects.png'
import CustomerBg from '../assets/innovation/Innovations-customerCentric-BG.png'
import Residential from '../assets/aboutUs/ResidentialBuildings.png'
import Commercial from '../assets/aboutUs/CommercialBuildings.png'
import Warehouse from '../assets/aboutUs/Warehouses.png'
import Factory from '../assets/aboutUs/FactoryBuildings.png'
import Coldhouse from '../assets/aboutUs/ColdStorages.png'
import CustomerCentricMask from '../assets/innovation/customerCentricMaskBg.png'
import ProcessFlow from '../assets/innovation/processflow.png'
import InnovationSlider from './InnovationSlider';
import Caraousel from './Caraousel';
import Innovationbgfull from '../assets/innovation/innovation_banner_full.png'
import First from '../assets/innovation/Customer First.png'
import Second from '../assets/innovation/Quality commitment.png'
import Third from '../assets/innovation/Driven by Innovations.png'
import Forth from '../assets/innovation/Driven by Innovations.png'


const cardsData = [
  {
    title: 'Customer First',
    description: 'At MBI, every member of our team is trained with a customer-first approach. Our professionals, drawing from experience with top companies in India, ensure that quality service and customer satisfaction are at the core of our operations. We build solutions tailored to our customers needs and expectations.',
    iconSrc: CustomerFirst,
    cardIcon: CustomerFirst,
    selectedIconSrc: First,
  },
  {
    title: 'Commitment to Quality',
    description: 'Our commitment to quality is unwavering. We adhere to the highest standards in every aspect of our work, from materials and design to construction and customer service. Our focus is on delivering projects that not only meet but exceed client expectations.',
    iconSrc: CommitmentQuality,
    cardIcon: CommitmentQuality,
    selectedIconSrc: Second,
  },
  {
    title: 'Driven By Innovation',
    description: 'Innovation is at the heart of everything we do. We continuously strive to improve our processes, incorporate new technologies, and find creative solutions to meet the evolving needs of our clients. Our goal is to stay ahead of the curve and set new industry standards.',
    iconSrc: DrivenInnovation,
    cardIcon: DrivenInnovation,
    selectedIconSrc: Third,
  },
  {
    title: 'Time Managed Projects',
    description: 'Innovation is at the heart of everything we do. We continuously strive to improve our processes, incorporate new technologies, and find creative solutions to meet the evolving needs of our clients. Our goal is to stay ahead of the curve and set new industry standards.',
    iconSrc: TimeManagement,
    cardIcon: TimeManagement,
    selectedIconSrc: Forth,
  },
];

const Innovation = () => {

    const [selectedCard, setSelectedCard] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <>
      {/* First Section */}
      <section className="aboutbgtest">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={Innovationbgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentinnovation">
            <h1>
              Innovation: Bridging <br />
              <span>Today with Tomorrow</span>
            </h1>
            <p>Revolutionary Solutions for Progressive Enterprises </p>
          </div>
        </div>
      </section>

      {/* Innovation Collage*/}
      <section className="innovationCollage">
        <div className="container">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 col-md-6">
                <img src={Collage} alt="Innovations" className="img-fluid" />
              </div>
              <div className="col-12 col-md-6">
                <h1>
                  Innovation: Bridging <br />
                  <span>Today with Tomorrow</span>
                </h1>
                <h5>Revolutionary Solutions for Progressive Enterprises</h5>
                <p>
                  At Metal Barns India (MBI), we simplify the journey of
                  establishing and managing facilities. Leveraging advanced
                  technology, we minimize leaders' involvement in non-productive
                  tasks, allowing them to focus on growth and innovation.
                </p>
                <p>
                  Our commitment is to deliver high-quality buildings,
                  engineered to precise specifications, while effectively
                  managing time and costs. Our mission is to empower
                  stakeholders and drive collective growth.
                </p>
                <Link to="/projects" className="link-button">
                  <button>
                    Our Projects <i className="fa-solid fa-arrow-right"></i>{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Customer centric */}
      <div className="position-relative w-100 py-2 bg-white">
        {/* Background Image */}
        <img
          src={CustomerBg}
          alt="Innovation Middle Background"
          className="position-absolute top-0 start-0 w-100 h-auto object-cover z-index-0"
        />

        {/* Customer-Centric Innovation Section */}
        <div className="container">
          <div className="position-relative mb-2 z-index-4 customerCentricHeading">
            <h2 className="text-center mb-1 mt-4">Customer-Centric Innovation</h2>
            <div className="d-flex justify-content-center">
              <div
                className="d-flex justify-content-center flex-wrap position-relative customerCentricDiv"
                style={{
                  backgroundImage: `url('/images/innovationmiddlesheet.png'),`,
                }}
              >
                {[
                  {
                    title: "Warehouse Without Column Foundations",
                    icon: Warehouse,
                  },
                  { title: "PEB Hanger Doors", icon: Factory },
                  {
                    title: "Steel Bungalow & Island Structure",
                    icon: Coldhouse,
                  },
                  { title: "Container Residences", icon: Residential },
                  { title: "Large Structures in Less Steel", icon: Commercial },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column align-items-center bg-white text-dark card-spacing position-relative customerCentric"
                    style={{ backgroundImage: `url(${CustomerCentricMask})` }}
                  >
                    <img
                      src={item.icon}
                      alt={item.title}
                      className="customerCentricImg"
                    />
                    <span className="small">{item.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Commitment To Quality Section */}
        {/* <div className="position-relative mb-5 z-index-10">
          <div
            className="position-absolute left-15 top-50 translate-middle bg-gray border rotate-45"
            style={{ width: "2.5rem", height: "2.5rem" }}
          ></div>
          <h2 className="text-center mb-4 position-relative">
            <span className="position-relative z-index-10 display-6">
              Commitment To <span className="text-primary">Quality</span>
            </span>
            <div className="mt-2 w-25 mx-auto border-top border-gray"></div>
          </h2>
          <div className="d-flex justify-content-center align-items-center bg-white shadow p-4">
            <img
              src={ProcessFlow}
              alt="Quality"
              className="w-50 h-auto object-cover"
            />
            <div className="p-4">
              <h3 className="h5 font-weight-bold">
                Process Flow & Ease Of Working
              </h3>
              <p className="text-muted">
                Description about process flow and ease of working.
              </p>
              <button className="mt-3 py-2 px-4 btn btn-primary bg-gradient">
                Learn More →
              </button>
            </div>
          </div>
        </div> */}
      </div>

      <InnovationSlider />

      <Caraousel />

      {/* MBI Advantage */}
      <section className="advantageMbi py-4 mt-5">
        <h2 className="d-flex justify-content-center align-items-center text-white advantageHeading">
          Advantage MBI
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-md-5 p-4 bg-transparent position-relative">
              {cardsData.map((card, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center p-4 mb-4 cursor-pointer transition-all duration-300 ${
                    selectedCard === index
                      ? "bg-custom selected-card"
                      : "bg-white text-dark"
                  }`}
                  onClick={() => setSelectedCard(index)}
                >
                  <img
                    src={
                      selectedCard === index
                        ? card.selectedIconSrc
                        : card.iconSrc
                    }
                    alt={card.title}
                    className="me-4"
                    style={{ width: "32px", height: "32px" }}
                  />
                  <span>{card.title}</span>
                  {selectedCard === index && <div className="arrow-right" />}
                  {isMobile && selectedCard === index && (
                    <div className="selected-card-content">
                      <div className="position-relative p-4">
                        <div className="position-relative z-index-20 p-4 bg-white text-dark">
                          <img
                            src={cardsData[selectedCard].cardIcon}
                            alt="Card Icon"
                            className="mb-4 selectedCardImg"
                            style={{ width: "64px", height: "64px" }}
                          />
                          <h2 className="h4 font-weight-bold mb-4 card-title-custom">
                            {cardsData[selectedCard].title}
                          </h2>
                          <p className="text-dark">
                            {cardsData[selectedCard].description}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {!isMobile && (
              <div className="col-md-7 p-4 customdivv">
                {selectedCard !== null && (
                  <div className="position-relative p-4">
                    <div className="position-relative z-index-20 p-4 text-dark">
                      <img
                        src={cardsData[selectedCard].cardIcon}
                        alt="Card Icon"
                        className="mb-4"
                        style={{ width: "64px", height: "64px" }}
                      />
                      <h2 className="h4 font-weight-bold mb-4 card-title-custom custom2">
                        {cardsData[selectedCard].title}
                      </h2>
                      <p className="text-dark">
                        {cardsData[selectedCard].description}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Brands we use */}
      <section className="container brands">
        <div className="brandsHeading">
          <h1 className="text-center">
            <span>Brands</span> We Use
          </h1>
        </div>
        <div className="row">
          {[
            Brand1,
            Brand2,
            Brand3,
            Brand4,
            Brand5,
            Brand6,
            Brand7,
            Brand8,
            Brand9,
            Brand10,
            Brand11,
            Brand12,
            Brand13,
          ].map((partner, index) => (
            <div className="col-6 col-md-3 mb-4" key={index}>
              <div className="card">
                <img
                  className="img-fluid"
                  src={partner}
                  alt={`Partner ${index + 1}`}
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Innovation;
