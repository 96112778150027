import "../styles/LatestTechnology.css";
import React, { useState } from 'react';
// import CompanyOwned from '../assets/innovation/caraousel/latestTech/companyownedfacility.png'
import CompanyOwned from '../assets/innovation/caraousel/latestTech/companyownedfacility1.jpg'
import CncCutting from '../assets/innovation/caraousel/latestTech/cnccutting1.jpg'
import Submerged from '../assets/innovation/caraousel/latestTech/submergedarcwelding.png'
import Beamline from '../assets/innovation/caraousel/latestTech/beamlineautoweld.png'
import Radial from '../assets/innovation/caraousel/latestTech/radialautodrilling.png'
import MtgWelding from '../assets/innovation/caraousel/latestTech/migtigwelding.png'
import AutoCad from '../assets/innovation/caraousel/latestTech/autocad1.jpg'
import CompanyOwnedIcon from '../assets/innovation/companyownedicon.png'
import CncCuttingIcon from '../assets/innovation/cncicon.png'
import SubmergedIcon from '../assets/innovation/submergedarcicon.png'
import BeamlineIcon from '../assets/innovation/beamlinearweldicon.png'
import RadialIcon from '../assets/innovation/radialicon.png'
import MtgWeldingIcon from '../assets/innovation/cncicon.png'
import AutocadIcon from '../assets/innovation/autocadicon.png'
import Latestbgfull from '../assets/innovation/latest_tech_banner_full.png'



const techData = [
  {
    title: 'Company Owned Facility',
    description: 'Metal Barns India owns over 1.6 acres of cutting-edge manufacturing space, with plans to expand to a dedicated 3-acre facility showcasing the best in industrial capability.',
    imgSrc: CompanyOwned,
    iconSrc: CompanyOwnedIcon
  },
  {
    title: 'CNC Cutting',
    description: 'Our advanced CNC cutting technology includes Plasma Cutting (16 mm), Oxy Fuel Cutting (150 mm), and a bed size of 2000 mm x 6500 mm.',
    imgSrc: CncCutting,
    iconSrc: CncCuttingIcon
  },
  {
    title: 'Submerged Arc Welder',
    description: 'The submerged arc welder ensures strong and consistent welds, crucial for the integrity of our steel structures.',
    imgSrc: Submerged,
    iconSrc: SubmergedIcon
  },
  {
    title: 'Beam Line Autowelder',
    description: 'Our beam line autowelder automates the welding process for beams, enhancing productivity and precision.',
    imgSrc: Beamline,
    iconSrc: BeamlineIcon
  },
  {
    title: 'Radial Auto Drilling',
    description: 'The radial auto drilling machines enable accurate and efficient drilling operations, supporting our manufacturing needs.',
    imgSrc: Radial,
    iconSrc: RadialIcon
  },
  {
    title: 'MTG/TIG Welding Machines',
    description: 'Our MTG/TIG welding machines provide high-quality welds, essential for the strength and durability of our structures.',
    imgSrc: MtgWelding,
    iconSrc: MtgWeldingIcon
  },
  {
    title: 'AutoCAD Design',
    description: 'We utilize AutoCAD design software to create precise and efficient designs, ensuring the best outcomes for our projects.',
    imgSrc: AutoCad,
    iconSrc: AutocadIcon
  }
];


const LatestTechnology = () => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const handleThumbnailClick = (index) => {
      setCurrentSlide(index);
    };

  return (
    <>
      {/* <section className="latestTechBannerImg">
        <div className="allContent">
          <div className="imgContent">
            <div className="imgContent1">
              <img src={Background} className="img-fluid" alt="Background" />
            </div>
            <div className="imgContent2">
              <img src={LatestTech} className="img-fluid" alt="Background2" />
            </div>
            <div className="textContent">
              <h1>
                <span>Latest Technology</span>
              </h1>
            </div>
          </div>
        </div>
      </section> */}

      <section className="aboutbgtestlatestTechno">
        <div className="allaboutbg">
          <div className="imgforbgLatest">
            <img src={Latestbgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentlatest">
            <h1>
              <span>Latest</span> Technology
            </h1>
          </div>
        </div>
      </section>

      {/* Caraousel */}
      <section className="caraousel">
        <section className="pt-5 pb-5 px-2 position-relative">
          <div className="container max-w-screen-2xl z-10 h-100">
            <div className="row align-items-center h-100">
              <div className="col-md-6 h-100 overflow-hidden rounded-lg">
                <img
                  src={techData[currentSlide].imgSrc}
                  alt={techData[currentSlide].title}
                  className="w-100 h-100 object-cover rounded-lg"
                />
              </div>
              <div
                className="col-md-6 p-5 rounded-lg d-flex flex-column h-100 latestTechBgMask"
                // style={{ backgroundImage: 'url(/images/latesttechbg.png)' }}
              >
                <img
                  src={techData[currentSlide].iconSrc}
                  alt={`${techData[currentSlide].title} icon`}
                  className="mb-2"
                  style={{ width: "3rem", height: "3rem" }}
                />
                <h2 className="display-5 mb-2 latestTechHeading">
                  {techData[currentSlide].title}
                </h2>
                <p className="lead flex-grow-1">
                  {techData[currentSlide].description}
                </p>
                {/* <p className="mt-2 text-muted">Photo: {techData[currentSlide].title}</p> */}
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-center space-x-0 latestTechmt">
              {techData.map((tech, index) => (
                <div
                  key={index}
                  className={`thumbnail-container relative w-25 h-25 rounded-lg overflow-hidden cursor-pointer transition-transform transform ${
                    currentSlide === index ? "z-15 scale-105" : "z-10"
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={tech.imgSrc}
                    alt={tech.title}
                    className="w-100 h-100 object-cover rounded-lg"
                  />
                  <div className="thumbnail-overlay d-flex align-items-center justify-content-center">
                    <img
                      src={tech.iconSrc}
                      alt={`${tech.title} icon`}
                      className="w-25 h-25"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default LatestTechnology;
