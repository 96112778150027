import "./App.css";
import ScrollToTop from "./ScrollToTop";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Innovation from "./components/Innovation";
import Navbar from "./components/Navbar";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Tabs from "./components/Tabs";
import Services from "./components/Services";
import CustomerCentric from "./components/CustomerCentric";
import CommitmentQuality from "./components/CommitmentQuality";
import Founder from "./components/Founder";
import ContactUs from "./components/ContactUs";
import LatestTechnology from "./components/LatestTechnology";
import InnovationMiddle from "./components/InnovationMiddle";
import InnovationSlider from "./components/InnovationSlider";
import Caraousel from "./components/Caraousel";
import Form from "./components/Form";
import Projects from "./components/Projects";
import './fonts.css'

function App() {
  return (
    <Router>
       <ScrollToTop />
      <div>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/innovations" element={<Innovation />} />
            <Route path="/tabs" element={<Tabs />} />
            <Route path="/services" element={<Services/>} />
            <Route path="/customer-centric" element={<CustomerCentric/>} />
            <Route path="/commitment-quality" element={<CommitmentQuality/>} />
            <Route path="/founders" element={<Founder/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/latest-technology" element={<LatestTechnology/>} />
            <Route path="/innovation-middle" element={<InnovationMiddle/>} />
            <Route path="/slider" element={<InnovationSlider/>} />
            <Route path="/caraousel" element={<Caraousel/>} />
            <Route path="/form" element={<Form/>} />
            <Route path="/projects" element={<Projects/>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
