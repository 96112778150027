import React, { useState } from "react";
import "../styles/Services.css";
import EPC from "../assets/services/epcforsteel1.jpg";
import degree from "../assets/services/360degreecompliance1.jpg";
import Layout from "../assets/services/layoutdesign.png";
import InnovativeEngi from "../assets/services/innovativeengineering1.jpg";
import IndustrialIcon from "../assets/Industrialand.png";
import EpcIcon from "../assets/EPCfor.png";
import DegreeIcon from "../assets/360degree.png";
import InnovationIcon from "../assets/InnovativeDesignandEngineering.png";
import Servicebgfull from '../assets/services/services_banner_full.png'

const servicesData = [
  {
    title: "EPC For Steel Structures",
    subtitle: "Precision, Performance, Perfection",
    description:
      "Our EPC solutions integrate cutting-edge engineering with superior project management to deliver robust steel structures that meet your exact specifications.",
    imgSrc: EPC,
    iconSrc: EpcIcon,
  },
  {
    title: "360 Degree Compliances",
    subtitle: "Comprehensive, Consistent, Compliant",
    description:
      "We ensure 360-degree compliance with all regulatory requirements and standards, providing you with peace of mind and operational efficiency.",
    imgSrc: degree,
    iconSrc: DegreeIcon,
  },
  {
    title: "Industrial And Logistics Layout Design",
    subtitle: "Innovative, Efficient, Effective",
    description:
      "Our layout design services optimize industrial and logistics operations, enhancing productivity and reducing costs.",
    imgSrc: Layout,
    iconSrc: IndustrialIcon,
  },
  {
    title: "Innovative Design And Engineering",
    subtitle: "Creative, Cutting-Edge, Customized",
    description:
      "Our innovative design and engineering solutions are tailored to meet the unique needs of your projects, ensuring maximum efficiency and effectiveness.",
    imgSrc: InnovativeEngi,
    iconSrc: InnovationIcon,
  },
];

const Services = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleButtonClick = (index) => {
    setCurrentSlide(index);
  };

  const getSlidePosition = (index) => {
    const totalSlides = servicesData.length;
    if (index === currentSlide) {
      return "center";
    } else if ((currentSlide - index + totalSlides) % totalSlides === 1) {
      return "left";
    } else if ((index - currentSlide + totalSlides) % totalSlides === 1) {
      return "right";
    }
    return "hidden";
  };

  return (
    <>
      {/* Landing Page */}
      {/* <section className="serviceBannerImg">
        <div className="allContent">
          <div className="imgContent">
            <div className="imgContent1">
              <img src={Background} className="img-fluid" alt="Background" />
            </div>
            <div className="imgContent2">
              <img src={AboutBg} className="img-fluid" alt="Background2" />
            </div>
            <div className="textContent">
              <h1>
                <span>Services</span>
              </h1>
            </div>
          </div>
        </div>
      </section> */}

      <section className="aboutbgtest">
        <div className="allaboutbg">
          <div className="imgforbg">
            <img src={Servicebgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentservices">
            <h1>Services</h1>
          </div>
        </div>
      </section>

      {/* Carousel */}
      <section className="px-3 position-relative w-100 carousel">
        <div className="container mx-auto position-relative">
          <div className="d-flex justify-content-center mb-4 classmyname">
            {servicesData.map((service, index) => (
              <button
                key={index}
                className={`btn mx-2 py-2 px-4 rounded-lg transition-colors duration-300 ${
                  currentSlide === index ? "btn-primary" : "btn-secondary"
                }`}
                onClick={() => handleButtonClick(index)}
              >
                {service.title}
              </button>
            ))}
          </div>
          <div
            className="position-relative d-flex justify-content-center align-items-center overflow-hidden imageImg"
            style={{ height: "400px" }}
          >
            {servicesData.map((service, index) => {
              const position = getSlidePosition(index);
              return (
                <div
                  key={index}
                  className={`position-absolute transition-all duration-500 transperentImg1 ${
                    position === "center"
                      ? "transform scale-100 z-2 opacity-100"
                      : position === "left"
                      ? "transform scale-75 opacity-30 z-1"
                      : position === "right"
                      ? "transform scale-75 opacity-30 z-1"
                      : "opacity-0 z-0"
                  }`}
                  style={{
                    width: position === "center" ? "60%" : "30%",
                    height: "auto",
                    left:
                      position === "center"
                        ? "50%"
                        : position === "left"
                        ? "20%"
                        : "80%",
                    transform:
                      position === "center"
                        ? "translateX(-50%)"
                        : position === "left"
                        ? "translateX(-100%)"
                        : "translateX(0)",
                  }}
                >
                  <img
                    src={service.imgSrc}
                    alt={service.title}
                    className="w-100 h-100 object-cover"
                  />
                </div>
              );
            })}
          </div>
          <div className="cardovercard">
            <div
              className="position-absolute bottom-10 start-70 translate-middle-y translate-middle-x w-50 p-3 bg-cover bg-no-repeat bg-white bg-opacity-90 rounded shadow-lg z-3 cardinner"
              style={{ backgroundImage: "url(/images/latesttechbg.png)" }}
            >
              <img
                src={servicesData[currentSlide].iconSrc}
                alt={`${servicesData[currentSlide].title} icon`}
                className="mb-2 iconStyle"
              />
              <h2 className="h3 servicesTitle">
                {servicesData[currentSlide].title}
              </h2>
              <h3 className="h5 servicesTitle1 mb-3">
                {servicesData[currentSlide].subtitle}
              </h3>
              <p className="h6">{servicesData[currentSlide].description}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
