import "../styles/Navbar.css";
import React, { useState, useEffect } from "react";
import Logo from "../assets/logoNew5.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [innovationOpen, setInnovationOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 990);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 990);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleInnovation = (e) => {
    e.preventDefault();
    setInnovationOpen(!innovationOpen);
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const closeNavbar = () => {
    setCollapsed(true);
  };

  return (
    <section className="home">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid bgcheck">
          <Link className="navbar-brand metalsLogo" to="/">
            <img src={Logo} alt="Metal Barns India" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={!collapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${!collapsed ? "show" : ""}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 p-3">
              <li className="nav-item dropdown dropdown1">
                <Link
                  className="nav-link dropdown-toggle text-white"
                  to="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="/about" onClick={closeNavbar}>
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/founders" onClick={closeNavbar}>
                      Founders
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown dropdown2">
                <Link
                  className="nav-link dropdown-toggle text-white"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="dropdown-submenu">
                    <Link
                      className="dropdown-item dropdown-toggle"
                      to="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleInnovation(e);
                      }}
                    >
                      Innovation
                    </Link>
                    {innovationOpen && (
                      <ul className="dropdown-menu show">
                        <li>
                          <Link className="dropdown-item" to="/innovations" onClick={closeNavbar}>
                            Innovation
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/customer-centric" onClick={closeNavbar}>
                            Customer-Centric Innovation
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/commitment-quality" onClick={closeNavbar}>
                            Commitment to Quality
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/latest-technology" onClick={closeNavbar}>
                            Latest Technology
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services" onClick={closeNavbar}>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/projects" onClick={closeNavbar}>
                      Projects
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown3">
                <Link className="nav-link text-white" to="/contact-us" onClick={closeNavbar}>
                  Contact Us
                </Link>
              </li>
              <li className="nav-item numberNav">
                <a className="nav-link active text-white" aria-current="page" href="#" onClick={closeNavbar}>
                  <i style={{ fontSize: '15px' }} className="fa-solid fa-phone"></i>+91 7620044077
                </a>
              </li>
              <li className="nav-item emailNav">
                <a className="nav-link text-white" href="mailto:info@metalbarns.in" onClick={closeNavbar}>
                  <i style={{ fontSize: '15px' }} className="fa-solid fa-envelope"></i>info@metalbarns.in
                </a>
              </li>
              <div className="social-icons d-flex">
                <Link
                  to="https://www.facebook.com/srgit/"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={closeNavbar}
                >
                  <i className="fa-brands fa-facebook"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/srgit/"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={closeNavbar}
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/srgit/"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={closeNavbar}
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <a
        href="https://api.whatsapp.com/send?phone=7620044077"
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-whatsapp my-float"></i>
      </a>
    </section>
  );
};

export default Navbar;
