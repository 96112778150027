import '../styles/InnovationSlider.css'
// AutoplaySlider.js
import { Link } from 'react-router-dom';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BestClass from "../assets/innovation/bestinclasstech.png";
import QualityConsume from "../assets/innovation/qualityconsumables.png";
import ProcessFlow from "../assets/innovation/processflow1.jpg";
import Documentation from "../assets/innovation/documentation.png";

const techData = [
  {
    title: 'Best in Class Technology',
    // description: 'Metal Barns India owns over 1.6 acres of cutting-edge manufacturing space, with plans to expand to a dedicated 3-acre facility showcasing the best in industrial capability.',
    imgSrc: BestClass,
  },
  {
    title: 'Quality Consumables & Accessories',
    imgSrc: QualityConsume,
  },
  {
    title: 'Process Flow',
    imgSrc: ProcessFlow,
  },
  {
    title: 'Comprehensive Documentation',
    imgSrc: Documentation,
  },
];

const InnovationSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }
    ]
  };

  return (
    <div className="container">
      <div className="sliderHeading">
        <h1>
          Commitment to <span>Quality</span>
        </h1>
        <Link to="/commitment-quality" className="link-button">
        <button>
          View All <i className="fa-solid fa-arrow-right"></i>
        </button>
        </Link>
      </div>
      <Slider {...settings}>
        {techData.map((tech, index) => (
          <div key={index} className="slider-item">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src={tech.imgSrc}
                  alt={tech.title}
                  className="w-100 rounded-lg"
                />
              </div>
              <div className="col-md-6 p-4">
                <h2 className="sliderText">{tech.title}</h2>
                {/* <p>{tech.description}</p> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InnovationSlider;
