
import "../styles/Projects.css";
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Projectbgfull from '../assets/project_banner_full.png';

const Projects = () => {
  const imgListRef = useRef(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get('https://api.nexaitech.com/api/projects/user/slug/metal-barns-india')
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the projects!", error);
      });
  }, []);

  const scrollRight = () => {
    imgListRef.current.scrollBy({ left: 750, behavior: 'smooth' });
  };

  const scrollLeft = () => {
    imgListRef.current.scrollBy({ left: -750, behavior: 'smooth' });
  };

  return (
    <>
      <section className="aboutbgtestprojects">
        <div className="allaboutbg">
          <div className="imgforbgProject">
            <img src={Projectbgfull} className="img-fluid" alt="About Us" />
          </div>
          <div className="allaboutcontentprojects">
            <h1>Projects</h1>
          </div>
        </div>
      </section>

      <section className="projectsCaraousel">
        <div className="container mb-5">
          <h2>
            {" "}
            Explore our<span> Latest Projects</span>
          </h2>
          <div className="scroll-arrow" onClick={scrollLeft}>
            &#8592;
          </div>
          <ul className="imgList" ref={imgListRef}>
            {projects.map(project => (
              <li key={project.id} className="project-item">
                <div className="project-overlay">
                  <img src={`https://api.nexaitech.com/${project.media}`} alt={project.name} />
                  <div className="overlay">
                    <h3>{project.name}</h3>
                    <p>{project.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="scroll-arrow" onClick={scrollRight}>
            &#8594;
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;






// import "../styles/Projects.css";
// import React, { useRef } from 'react';
// import ProjectBg from "../assets/projectBg.png";
// import Background from "../assets/aboutUs/aboutmask.png";
// import ProjectImg from '../assets/project1.png'
// import Projectbgfull from '../assets/project_banner_full.png'



// const Projects = () => {
//   const imgListRef = useRef(null);

//   const scrollRight = () => {
//     imgListRef.current.scrollBy({ left: 750, behavior: 'smooth' });
//   };

//   const scrollLeft = () => {
//     imgListRef.current.scrollBy({ left: -750, behavior: 'smooth' });
//   };

//   return (
//     <>
//       <section className="aboutbgtestprojects">
//         <div className="allaboutbg">
//           <div className="imgforbgProject">
//             <img src={Projectbgfull} className="img-fluid" alt="About Us" />
//           </div>
//           <div className="allaboutcontentprojects">
//             <h1>Projects</h1>
//           </div>
//         </div>
//       </section>

//       {/* caraousel */}
//       <section className="projectsCaraousel">
//         <div className="container mb-5">
//           <h2>
//             {" "}
//             Explore our<span> Latest Projects</span>
//           </h2>
//           <div className="scroll-arrow" onClick={scrollLeft}>
//             &#8592;
//           </div>
//           <ul className="imgList" ref={imgListRef}>
//             <li>
//               <img src={ProjectImg} alt="Image 1" />
//             </li>
//             <li>
//               <img src={ProjectImg} alt="Image 2" />
//             </li>
//             <li>
//               <img src={ProjectImg} alt="Image 3" />
//             </li>
//             <li>
//               <img src={ProjectImg} alt="Image 4" />
//             </li>
//             <li>
//               <img src={ProjectImg} alt="Image 5" />
//             </li>
//             <li>
//               <img src={ProjectImg} alt="Image 6" />
//             </li>
//           </ul>
//           <div className="scroll-arrow" onClick={scrollRight}>
//             &#8594;
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };
// export default Projects;
